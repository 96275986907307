
@import './../../../../scss/theme-bootstrap';

///
/// @file product_grid_v1.scss
///
/// \brief Styles for multi-product grids
///
///

.product-grid {
  width: 100%;
  position: relative;
  text-align: center;
  .mpp__align-rows-left & {
    text-align: #{$ldirection};
  }
  .product-grid__content {
    @include pie-clearfix;
    margin: 0 auto 1px;
  }
  .product-grid__item {
    @include swap_direction(margin, 0 0 20px 0);
    width: 49.5%;
    display: inline-block;
    vertical-align: top;
    &.hide {
      display: none;
    }
    &--hover {
      .product-image--hover {
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s;
        @include breakpoint($large-up) {
          &:hover {
            opacity: 1;
            #{$ldirection}: 0;
            #{$rdirection}: 0;
          }
        }
      }
    }
  }
  &.product-grid--small-cols-2,
  &.product-grid--small-cols-3 {
    .product-grid__item {
      float: none;
      .mpp__align-rows-left & {
        float: #{$ldirection};
      }
    }
  }
  &.product-grid--small-cols-2 .product-grid__item {
    width: 49%;
    &.product-grid__item--colspan-2 {
      width: 100%;
      margin-top: 30px;
    }
  }
  &.product-grid--small-cols-3 .product-grid__item {
    width: 33%;
  }
  @include breakpoint($medium-up) {
    .product-grid__item {
      float: none;
      width: 24.5%;
      margin-bottom: 40px;
      .mpp__align-rows-left & {
        float: #{$ldirection};
      }
    }
    &.product-grid--medium-cols-1 .product-grid__item {
      width: auto;
      float: none;
    }
    &.product-grid--medium-cols-2 .product-grid__item {
      width: 49.5%;
    }
    &.product-grid--medium-cols-3 .product-grid__item {
      width: 33%;
    }
    &.product-grid--medium-cols-4 .product-grid__item {
      width: 24.5%;
      &.product-grid__item--colspan-2 {
        width: 49.5%;
        margin-top: 0;
      }
      &.product-grid__item--colspan-4 {
        width: 100%;
      }
    }
  }
  /* carousel: mpp & spp cross-sell grids */

  &.product-grid--carousel {
    .product-grid__content {
      .product-grid__item {
        margin: 0px;
      }
      width: 90%;
      @include breakpoint($medium-up) {
        width: 96%;
      }
    }
    .product__image-link {
      margin-bottom: 50px;
      @include breakpoint($medium-up) {
        margin: 0px;
      }
    }
  }
  .carousel-controls {
    top: 40%;
    text-align: #{$ldirection};
    .slick-prev {
      @include breakpoint($medium-up) {
        #{$ldirection}: -25px;
      }
    }
    .slick-next {
      @include breakpoint($medium-up) {
        #{$rdirection}: -25px;
      }
    }
  }
  .carousel-dots {
    position: absolute;
    z-index: 2;
    margin-top: 0px;
    top: 49%;
    @include breakpoint(321px) {
      top: 100%;
    }
    @include breakpoint($medium-up) {
      display: none;
      top: 310px;
    }
    .mpp__show-column-header & {
      top: 365px;
    }
  }
  /* mpp-specific touts */
  .product-grid__tout {
    height: 100%;
    background-color: $color-black;
    color: $color-light-stone-gray;
    .tout__text {
      background-color: $color-black;
    }
    .tout__text-inner {
      padding: 20px 16px 8px;
      @include breakpoint($medium-up) {
        padding: 28px 30px 20px;
      }
    }
    .tout__copy {
      padding: 10px 0 0;
      @include breakpoint($medium-up) {
        padding: 16px 0 6px;
      }
    }
    .tout__cta {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 10px;
      margin: 8px 0;
      @include breakpoint($medium-up) {
        letter-spacing: 0.2em;
        font-size: 11px;
      }
      a {
        padding-top: 12px;
        padding-bottom: 12px;
        border: 0;
        color: $color-light-stone-gray;
        &:hover {
          color: $color-light-stone-gray;
        }
      }
    }
    &--inverted-white {
      @include font-smoothing(none);
      background-color: $color-white;
      color: $color-black;
      .tout__text {
        background-color: $color-white;
      }
      .tout__cta {
        a {
          color: $color-black;
          &:hover {
            color: $color-black;
          }
        }
      }
    }
  }
}
